<template>
  <div class="gmap_canvas gmap_canvag-small">
    <iframe
      id="gmap_canvas"
      src="https://maps.google.com/maps?q=Dhaka%20bangladesh&t=&z=13&ie=UTF8&iwloc=&output=embed"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    ></iframe>
  </div>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>